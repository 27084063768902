/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Icon,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {Grid} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {useDropzone} from "react-dropzone";
import {CustomButton, CustomIconButton} from "ui";
import React, {useEffect, useMemo} from "react";

type UploaderTypes = {
  acceptType?: string | Array<string>;
  setFormattedFiles?: React.Dispatch<React.SetStateAction<any[]>>;
  formattedFiles?: any[];
  group?: string;
  handleSetDocuments: (acceptedFiles?: any, group?: any) => void;
  singleFile?: boolean;
  fileNameWithTextOverflowElipsis?: boolean;
  width?: string;
  customFileNamePrefix?: string;
  customFileNameStartsAt?: number;
  isVertical?: boolean;
  disabled?: boolean;
};

const CustomUploader = (props: UploaderTypes) => {
  const {
    acceptType,
    group = "",
    handleSetDocuments,
    setFormattedFiles = () => void 0,
    formattedFiles,
    singleFile = false,
    fileNameWithTextOverflowElipsis,
    customFileNamePrefix,
    customFileNameStartsAt = 0,
    isVertical = true,
    disabled = false,
  } = props;
  const {screenWidth} = WindowDimensions();
  const [uploadedFiles, setUploadedFiles] = React.useState<any[]>([]);

  const assignFormattedFilesToUploadedFiles = () => {
    if (formattedFiles && formattedFiles?.[0]?.url?.length > 0) {
      setUploadedFiles(formattedFiles);
    } else {
      setUploadedFiles([]);
    }
  };
  let fileNumber = useMemo(
    () =>
      customFileNameStartsAt +
      Number(
        uploadedFiles[uploadedFiles.length - 1]?.name.split("_").reverse()[0] ??
          0,
      ) +
      1,
    [],
  );
  const onDrop = (acceptedFiles: any) => {
    const uploadedTemp: any = uploadedFiles;
    if (customFileNamePrefix) {
      fileNumber =
        Number(
          uploadedFiles[uploadedFiles.length - 1]?.name
            .split("_")
            .reverse()[0]
            .split(".")[0] ?? customFileNameStartsAt,
        ) + 1;

      for (const file of acceptedFiles as File[]) {
        const fileExtension = file?.name?.split(".")?.reverse()?.[0];
        const renamedFile = new File(
          [file],
          `${customFileNamePrefix}_${fileNumber < 10 ? "0" : ""}${fileNumber}.${fileExtension?.toLowerCase()}`,
          {
            type: file.type,
            lastModified: file.lastModified,
          },
        );

        uploadedTemp.push(...[renamedFile]);
        fileNumber++;
      }
    } else {
      uploadedTemp.push(...acceptedFiles);
    }
    handleSetDocuments([...uploadedTemp], group);
    setUploadedFiles([...uploadedTemp]);
  };

  const handleDeleteFile = (item: any) => {
    const temp = uploadedFiles;
    const index = temp.indexOf(item);

    if (index !== -1) {
      temp.splice(index, 1);
    }
    handleSetDocuments([...temp]);
    setUploadedFiles([...temp]);
    setFormattedFiles([...temp]);
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: acceptType,
    disabled: disabled,
  });

  const showDropArea = () => {
    if (!singleFile || (singleFile && uploadedFiles.length === 0)) {
      return true;
    } else {
      return false;
    }
  };

  const showUploadedFiles = () => {
    if (!singleFile || (singleFile && uploadedFiles.length > 0)) {
      return true;
    } else {
      return false;
    }
  };

  const uploadedFilesTitle = () => {
    switch (uploadedFiles.length) {
      case 0:
        return isVertical ? null : `Nenhum arquivo enviado`;
    }
  };

  useEffect(() => {
    assignFormattedFilesToUploadedFiles();
  }, [formattedFiles]);

  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `${pxToRem(1.5)} solid lightgrey`,
        borderRadius: pxToRem(3),
        marginTop: pxToRem(8),
        width: "100%",
        color: "text.primary",
      }}
    >
      <Grid
        container
        sx={{
          padding: pxToRem(8),
        }}
      >
        {showDropArea() && (
          <Grid
            size={singleFile || isVertical ? 12 : 4}
            sx={{
              height: screenWidth <= 768 ? "50%" : "auto",
              color: disabled ? "grey" : "text.primary",
            }}
          >
            <Box
              {...getRootProps({className: "dropzone"})}
              sx={{
                border: "1px dashed grey",
                borderRadius: pxToRem(5),
                display: "flex",
                flexDirection: "column",
                alignItems: "center;",
                padding: pxToRem(15),
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <Icon
                fontSize="large"
                sx={{
                  color: disabled
                    ? theme.palette.grey[100]
                    : theme.palette.grey[700],
                }}
              >
                cloud_upload
              </Icon>
              <Typography>Arraste e solte seu arquivo aqui</Typography>
              <Typography sx={{margin: pxToRem(4)}}>ou</Typography>
              <CustomButton disabled={disabled}>
                Escolha seu arquivo
              </CustomButton>
            </Box>
          </Grid>
        )}
        {showUploadedFiles() && (
          <Grid
            size={singleFile || isVertical ? 12 : 8}
            sx={{
              minHeight: screenWidth <= 768 ? pxToRem(160) : "auto",
              p: isVertical ? "0" : `0 ${pxToRem(20)}`,
            }}
          >
            {uploadedFilesTitle() && (
              <Typography
                component="p"
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontWeight: 400,
                  p: isVertical ? `${pxToRem(15)} 0` : "0",
                }}
              >
                {uploadedFilesTitle()}
              </Typography>
            )}

            <Grid
              container
              className={"scrollBar"}
              sx={{
                maxHeight: pxToRem(150),
                marginTop: pxToRem(5),
                overflowX: "hidden",
              }}
            >
              {uploadedFiles.map((file: any) => (
                <Grid
                  key={file?.name + file.lastModified}
                  size={12}
                  sx={{display: "flex", alignItems: "center"}}
                >
                  <Box
                    width={pxToRem(150)}
                    sx={{display: "flex", justifyContent: "center"}}
                  >
                    {file.type.includes("image") ? (
                      <img
                        width={60}
                        src={
                          formattedFiles ? file.url : URL.createObjectURL(file)
                        }
                      />
                    ) : (
                      <Icon sx={{fontSize: pxToRem(50)}}>description</Icon>
                    )}
                  </Box>
                  <Grid
                    container
                    size={12}
                    sx={{display: "flex", flexDirection: "column"}}
                  >
                    <Grid
                      size={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: pxToRem(10),
                        paddingRight: pxToRem(10),
                      }}
                    >
                      <Grid
                        container
                        flexGrow={1}
                        sx={{
                          maxWidth: fileNameWithTextOverflowElipsis
                            ? pxToRem(isVertical ? 350 : 120)
                            : "auto",
                        }}
                      >
                        <Tooltip
                          title={file.name ?? ""}
                          arrow
                          placement="top-start"
                        >
                          <Typography noWrap>{file.name}</Typography>
                        </Tooltip>
                      </Grid>
                      <Tooltip
                        title={`${(file?.size / 1024).toFixed(2)} kB`}
                        arrow
                        placement="top"
                      >
                        <Typography noWrap width={pxToRem(80)}>
                          {file.size
                            ? `/ ${(file?.size / 1024).toFixed(0)} kB`
                            : ""}
                        </Typography>
                      </Tooltip>
                      <CustomIconButton
                        tooltipText="Excluir arquivo"
                        iconName="delete_forever"
                        onClick={() => handleDeleteFile(file)}
                      />
                    </Grid>
                    <Grid
                      size={screenWidth > 500 ? 10 : 12}
                      sx={{marginLeft: pxToRem(10)}}
                    >
                      <LinearProgress value={100} variant="determinate" />
                      <Typography>100%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CustomUploader;
