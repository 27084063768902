import {TextField} from "@mui/material";
import {Grid} from "@mui/system";
import {DefaultCardStructure, ProcessDatePicker} from "components";
import pxToRem from "hooks/usePxToRem";
import {useFormContext, Controller} from "react-hook-form";
import {CustomInputWrapper, CustomInput, CustomToggle, CustomSelect} from "ui";
import {ModalityPayment, PlaceIssue} from "util/globalEnums";

const ActivityMaster = () => {
  const {control, getValues} = useFormContext();
  const inputWrapperGridSize = 2;

  return (
    <DefaultCardStructure
      title="BL Master"
      hasDivider={getValues("masterBl.directMaster")}
      isSubcard
    >
      <Grid container columnSpacing={pxToRem(3)}>
        <CustomInputWrapper xs={inputWrapperGridSize} title="BL n°" required>
          <Controller
            name="masterBL.number"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="masterBL.number"
                type="text"
                placeholder="BL n°"
                value={value ?? ""}
                onChange={onChange}
                size="small"
                required
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Data BL">
          <Controller
            name="masterBL.dateIssue"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name={`masterBL.dateIssue`}
                title="Data BL"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper
          xs={inputWrapperGridSize}
          title="Modelo De Pagamento"
          isDisabled
        >
          <Controller
            name="masterBL.paymentMethod"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                name="masterBL.paymentMethod"
                value={value ?? ""}
                onChange={onChange}
                content={Object.values(ModalityPayment)}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="CE n°" isDisabled>
          <Controller
            name="masterBL.deconsolidationNumber"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="masterBL.deconsolidationNumber"
                value={value ?? ""}
                placeholder="CE n°"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Data CE">
          <Controller
            name="masterBL.dateDeconsolidation"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name={`masterBL.dateDeconsolidation`}
                title="Data CE"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Local Emissão">
          <Controller
            name="masterBL.placeIssue"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                name="masterBL.placeIssue"
                value={value ?? ""}
                onChange={onChange}
                content={Object.values(PlaceIssue)}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <Grid size={inputWrapperGridSize}>
          <Controller
            name="masterBL.directMaster"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomToggle
                name="masterBL.directMaster"
                label="BL Direto"
                labelPosition="end"
                isDisabled
                onToggleSwitch={onChange}
                isChecked={!!value}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        {!!getValues("masterBL.directMaster") && (
          <CustomInputWrapper md={12} title="Descrição dos pacotes" required>
            <Controller
              name={
                `masterBL.logisticBillLading.descriptionOfPackages` as const
              }
              control={control}
              render={({field: {value, onChange}}) => (
                <TextField
                  multiline
                  minRows={4}
                  maxRows={8}
                  size="small"
                  name={`masterBL.logisticBillLading.descriptionOfPackages`}
                  value={value ?? ""}
                  placeholder="descrição"
                  onChange={onChange}
                  sx={{fontSize: pxToRem(14)}}
                  required
                />
              )}
            />
          </CustomInputWrapper>
        )}
      </Grid>
    </DefaultCardStructure>
  );
};

export default ActivityMaster;
