import {Fragment, ReactNode} from "react";
import {Divider} from "@mui/material";
import {Grid} from "@mui/system";
import pxToRem from "hooks/usePxToRem";

type Props = {
  children: ReactNode;
  hideDivider?: boolean;
};

const DefaultModalFooter = (props: Props) => {
  const {children, hideDivider = false} = props;

  return (
    <Fragment>
      <Grid container size={12}>
        <Grid size={12}>
          <Divider
            sx={{
              display: hideDivider ? "none" : "",
              color: "red",
              height: "5px",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid container size={12} p={pxToRem(20)} spacing={pxToRem(8)}>
        {children}
      </Grid>
    </Fragment>
  );
};
export default DefaultModalFooter;
