import {ReactNode} from "react";
import pxToRem from "hooks/usePxToRem";

import {Typography, Tooltip} from "@mui/material";
import {Grid, SxProps, Theme} from "@mui/system";

type WrapperProps = {
  children: ReactNode;
  isDisabled?: boolean;
  title: string;
  tooltip?: string;
  required?: boolean;
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
  sx?: SxProps<Theme>;
  typographyVariant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
  helper?: ReactNode;
};

const CustomInputWrapper = (props: WrapperProps) => {
  return (
    <Grid
      size={{
        xs: props.xs ?? 12,
        md: props.md ?? props.xs ?? 12,
        lg: props.lg ?? props.md ?? props.xs ?? 12,
        xl: props.xl ?? props.lg ?? props.md ?? props.xs ?? 12,
      }}
      display="flex"
      flexDirection="column"
      sx={{gap: pxToRem(4), ...props.sx, width: "100%"}}
    >
      <Tooltip title={props.tooltip ?? ""} arrow placement="top-start">
        <Typography
          variant={props.typographyVariant ?? "label1"}
          component="label"
          noWrap
          sx={{opacity: props.isDisabled ? 0.8 : 1}}
        >
          {props.title}
          <Typography
            component="span"
            color={props.required ? "error" : "transparent"}
          >
            *
          </Typography>
          {props?.helper ?? <></>}
        </Typography>
      </Tooltip>
      {props.children}
    </Grid>
  );
};

export default CustomInputWrapper;
