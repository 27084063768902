import {styled} from "@mui/material";
import {Grid} from "@mui/system";

import pxToRem from "hooks/usePxToRem";

interface DatePickerWrapperProps {
  fullWidth: boolean;
}

const forwardProp = {
  shouldForwardProp: (prop) => prop !== "fullWidth",
};

const DatePickerWrapper = styled(
  Grid,
  forwardProp,
)<DatePickerWrapperProps>(
  ({theme, fullWidth}) => `

    display: flex;
    width: ${fullWidth ? "100%" : "auto"};

    .react-datepicker {
      font-family: "Poppins";
      font-size: ${pxToRem(12)};
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: ${theme.palette.text.secondary};
      border: none;
      background-color: ${theme.palette.background.paper};
      border: 1px solid ${theme.palette.background.default};

      &-wrapper {
        width: ${fullWidth ? "100%" : "auto"};
      }

      &-popper {
        z-index: 10;
      }

      &__day {
        margin: 0;
        width: ${pxToRem(32)};
        height: ${pxToRem(26)};
        
        color: ${theme.palette.text.secondary};
        
          :hover {
            background-color: ${theme.palette.primary[50]};
            color: ${theme.palette.primary[700]};
          }

          &--outside-month {
            opacity: 0.7;
          }

          &--selected{
            background-color: ${theme.palette.primary[600]};
            color: ${theme.palette.background.paper} !important;
            border-radius: ${pxToRem(4)};
          }

          &--today{
            color: ${theme.palette.primary[700]} ;
            fontWeight: 500;
            position: relative;
            z-index: 1;
          }

          &--in-selecting-range {
            background-color: ${theme.palette.primary[50]} !important;
            color: ${theme.palette.primary[700]};
            border-radius: 0;
          }

          &--keyboard-selected {
            background-color: ${theme.palette.primary[50]};
            color: ${theme.palette.primary[700]};
          }
    }


  .react-datepicker__day--today::after,
  .react-datepicker__day--today::before {
    content: "";
    position: absolute;
  }

 .react-datepicker__day--today::before {
    //background-color: ${theme.palette.background.paper} !important;
    outline: ${pxToRem(1)} solid ${theme.palette.primary[600]};
    outline-offset: ${pxToRem(-1)} ;
    border-radius: ${pxToRem(4)} !important;
    color: ${theme.palette.primary[700]} !important;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: ${pxToRem(4)};
  }

      
  .react-datepicker__day--in-range {
    background-color: ${theme.palette.primary[50]};
    color: ${theme.palette.primary[700]};
    border-radius: 0;
      
      &-end{
        background-color: ${theme.palette.primary[600]};
        border-radius: ${pxToRem(4)};
        color: ${theme.palette.background.paper};
      }

      &-start{
        background-color: ${theme.palette.primary[50]};
        color: ${theme.palette.background.paper};
        border-radius: ${pxToRem(4)} 0 0 ${pxToRem(4)};
        position: relative;
        z-index: 1;
      }
  }

  .react-datepicker__day--range-end  {
    background-color: ${theme.palette.primary[50]};
    color: ${theme.palette.background.paper};
    border-radius: 0 ${pxToRem(4)} ${pxToRem(4)} 0;
    position: relative;
    z-index: 1;
  }

  .react-datepicker__day--range-start {
    background-color: ${theme.palette.primary[50]};
    color: ${theme.palette.background.paper};
    border-radius: ${pxToRem(4)} 0 0 ${pxToRem(4)};
    position: relative;
    z-index: 1;
  }

  .react-datepicker__day--range-start::after,
  .react-datepicker__day--range-start::before,
  .react-datepicker__day--range-end::after,
  .react-datepicker__day--range-end::before {
    content: "";
    position: absolute;
  }

  .react-datepicker__day--range-start::before,
  .react-datepicker__day--range-end::before {
    background-color: ${theme.palette.primary[600]};
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: ${pxToRem(4)};
  }

  .react-datepicker__week {
    margin: ${pxToRem(4)};
  }

  //header
  .react-datepicker__day-name {
    color: ${theme.palette.text.primary};
    margin: 0;
    height: ${pxToRem(26)};
    width: ${pxToRem(32)};
    font-weight: 500;
    text-transform: capitalize;
  }

  .react-datepicker__header {
    background-color: ${theme.palette.background.paper};
    border-bottom: none;
    padding-top: ${pxToRem(12)};
  }



  // primeiro e último dia enquanto selecionando
  .react-datepicker__day--selecting-range-start {
    background-color: ${theme.palette.primary[200]} !important;
    border-radius: ${pxToRem(4)} 0 0 ${pxToRem(4)};
    color: ${theme.palette.primary[700]} !important;
  }

  .react-datepicker__day--selecting-range-end {
    background-color: ${theme.palette.primary[200]} !important;
    border-radius: 0 ${pxToRem(4)} ${pxToRem(4)} 0;
    color: ${theme.palette.primary[700]} !important;

  }


  // round border sábados e domingos
  div.react-datepicker__day--in-range.react-datepicker__day--weekend:first-of-type {
    border-radius: ${pxToRem(4)} 0 0 ${pxToRem(4)};
  }
  div.react-datepicker__day--in-range.react-datepicker__day--weekend:last-of-type {
    border-radius: 0 ${pxToRem(4)} ${pxToRem(4)} 0;
  }

  div.react-datepicker__day--in-selecting-range.react-datepicker__day--weekend:first-of-type {
    border-radius: ${pxToRem(4)} 0 0 ${pxToRem(4)};
  }
  div.react-datepicker__day--in-selecting-range.react-datepicker__day--weekend:last-of-type {
    border-radius: 0 ${pxToRem(4)} ${pxToRem(4)} 0;
  }

  .react-datepicker__children-container{
    margin: 0;
    width: auto;
    padding: 0;
  }
`,
);

export default DatePickerWrapper;
