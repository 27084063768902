import {Chip} from "@mui/material";
import {Grid} from "@mui/system";
import {Box, useTheme} from "@mui/system";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import pxToRem from "hooks/usePxToRem";
import {useState, useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {
  GetEquipmentsList,
  GetPackageContainersList,
} from "services/api_v2/Processes/Processes.service";
import {
  CustomInputWrapper,
  CustomSelect,
  CustomInput,
  CustomAutocomplete,
  CustomIconButton,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {TypeItemLoad} from "util/globalEnums";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {ProcessEquipment} from "views/ProcessDetails/Process.types";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

type Props = {
  field: any;
  index: number;
  handleDeleteLogisticBillLading: (field: any, index: number) => Promise<void>;
  setHouseMeasurements: (shouldDirtyFields?: boolean) => void;
  cloneItemConfig: {
    icon: "content_copy" | "content_paste" | "close";
  };
  handleClickOnCloneItem: (index: number, valuesToClone: any) => void;
  isCloningItem: boolean;
};

const CargoItem = (props: Props) => {
  const {
    cloneItemConfig,
    isCloningItem,
    handleClickOnCloneItem,
    field,
    index,
    handleDeleteLogisticBillLading,
    setHouseMeasurements,
  } = props;
  const {control, setValue, getValues} = useFormContext();
  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );

  const getEquipmentsIds = () => {
    const equipments: ProcessEquipment[] = getValues("logisticEquipments");
    const idsList = equipments?.map((item) => item?.equipment?.id);
    return idsList;
  };

  const [equipmentsIdsList] =
    useState<(number | undefined)[]>(getEquipmentsIds());

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [searchPackages, setSearchPackages] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackageContainersList);

  const [
    extraSpaceWhenCargoIsNotDangerous,
    setExtraSpaceWhenCargoIsNotDangerous,
  ] = useState(0);

  const DANGEROUS_FIELD_SIZE = 1;
  const [isCargoDangerous] = useState(
    getValues(`processLoads[0].cargoDangerous`),
  );

  useEffect(() => {
    if (!isCargoDangerous) {
      setExtraSpaceWhenCargoIsNotDangerous((2 * DANGEROUS_FIELD_SIZE) / 10);
    }
  }, []);

  const theme = useTheme();

  const getTooltipTextAndIconColor = (icon) => {
    if (icon === "content_copy")
      return {text: "Copiar item", iconColor: theme.palette.text.primary};

    if (icon === "content_paste")
      return {
        text: "Colar item copiado aqui",
        iconColor: theme.palette.info[500],
      };

    if (icon === "close")
      return {text: "Encerrar clonagem", iconColor: theme.palette.error[500]};
    return {text: ""};
  };

  return (
    <Grid key={field.idForm} container columnSpacing={pxToRem(1)}>
      {isCloningItem && (
        <CustomInputWrapper title="" xs={0.3}>
          <CustomIconButton
            iconName={cloneItemConfig.icon}
            onClick={() => {
              handleClickOnCloneItem(
                index,
                getValues("billLadingLoads")[index],
              );
            }}
            tooltipText={getTooltipTextAndIconColor(cloneItemConfig.icon).text}
            sx={{
              ":hover": {
                color: getTooltipTextAndIconColor(cloneItemConfig.icon)
                  .iconColor,
              },
            }}
          />
        </CustomInputWrapper>
      )}
      <CustomInputWrapper xs={0.4} title="">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Chip label={index + 1} color="primary" size="small" />
        </Box>
      </CustomInputWrapper>

      <CustomInputWrapper
        title="Tipo item"
        xs={0.6 + extraSpaceWhenCargoIsNotDangerous + (isCloningItem ? 0 : 0.3)}
        tooltip="Tipo item carga"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.typeItemLoad`}
          control={control}
          render={({field: {onChange, value}}) => (
            <CustomSelect
              content={Object.values(TypeItemLoad)}
              value={value ?? ""}
              name={`billLadingLoads.${index}.typeItemLoad`}
              onChange={(e) => {
                onChange(e);
              }}
              size="small"
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
        title="C. Num "
        required
      >
        <Controller
          name={`billLadingLoads.${index}.number`}
          control={control}
          render={({field: {value, onChange}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.number`}
              type="text"
              placeholder="N° container"
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
              }}
              size="small"
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
        title="Selo"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.seal`}
          control={control}
          render={({field: {value, onChange}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.seal`}
              type="text"
              placeholder="Selo"
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
              }}
              size="small"
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="BarSeal"
      >
        <Controller
          name={`billLadingLoads.${index}.barSeal`}
          control={control}
          render={({field: {value, onChange}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.barSeal`}
              type="text"
              placeholder="BarSeal"
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
        title="Equipamento"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.equipment`}
          control={control}
          render={({field: {value}}) => (
            <CustomAutocomplete
              name={`billLadingLoads.${index}.equipment`}
              placeholder="Equipamento"
              tooltipText={getTooltipText(value?.name, 3)}
              loading={isLoadingEquipmentsOptions}
              options={mountOptions(value, equipmentsOptions)}
              value={value?.name ?? null}
              onOpen={() => getEquipmentsOptions(setEquipmentsOptions, 1, "")}
              onInputChange={(e, value) => {
                if (e !== null) {
                  setSearch(value);
                  getEquipmentsOptions(setEquipmentsOptions, 1, search);
                }
              }}
              getNextPage={() => {
                onBottomScrollEquipments(setEquipmentsOptions, search);
              }}
              onChange={(e, value) => {
                handleChangeAutocomplete(
                  e,
                  value,
                  `billLadingLoads.${index}.equipment`,
                );
              }}
              isOptionEqualToValue={(option, value) => value === option?.label}
              getOptionDisabled={(option) =>
                !equipmentsIdsList?.includes(option?.id)
              }
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.5 + extraSpaceWhenCargoIsNotDangerous}
        title="Quant."
        tooltip="Quantidade"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.quantity`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.quantity`}
              type="number"
              placeholder="Quant."
              value={value ?? ""}
              onChange={(e) => {
                setValue(`billLadingLoads.${index}.quantity`, +e.target.value, {
                  shouldDirty: true,
                });
                setHouseMeasurementsDebounced();
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 1.0,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              required
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
        title={`Pacote`}
        required
      >
        <Controller
          name={`billLadingLoads.${index}.packageContainer`}
          control={control}
          render={({field: {value}}) => (
            <CustomAutocomplete
              name={`billLadingLoads.${index}.packageContainer`}
              placeholder="Pacote"
              tooltipText={getTooltipText(value?.name, 3)}
              loading={isLoadingPackagesOptions}
              options={mountOptions(value, packagesOptions)}
              value={value?.name ?? null}
              onOpen={() => getPackagesOptions(setPackagesOptions, 1, "")}
              onInputChange={(e, value) => {
                if (e !== null) {
                  setSearchPackages(value);
                  getPackagesOptions(setPackagesOptions, 1, searchPackages);
                }
              }}
              getNextPage={() => {
                onBottomScrollPackages(setPackagesOptions, searchPackages);
              }}
              onChange={(e, value) => {
                handleChangeAutocomplete(
                  e,
                  value,
                  `billLadingLoads.${index}.packageContainer`,
                );
              }}
              isOptionEqualToValue={(option, value) => value === option?.label}
              required
            />
          )}
        />
      </CustomInputWrapper>
      {/* Peso líquido oculto por enquanto */}
      {/* <CustomInputWrapper md={1.5} title="Peso líq.">
                <Controller
                  name={`billLadingLoads.${index}.netWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.netWeight`}
                      type="number"
                      placeholder="Peso liq."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.netWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                      }}
                    />
                  )}
                />
              </CustomInputWrapper> */}
      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="P. Bruto"
        tooltip="Peso bruto - kg"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.grossWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.grossWeight`}
              type="number"
              placeholder="Peso bruto"
              value={value ?? ""}
              onChange={(e) => {
                setValue(
                  `billLadingLoads.${index}.grossWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                setHouseMeasurementsDebounced();
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="CBM"
        required
      >
        <Controller
          name={`billLadingLoads.${index}.measurement`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.measurement`}
              type="number"
              placeholder="medidas"
              value={value?.toString() ?? ""}
              onChange={(e) => {
                setValue(
                  `billLadingLoads.${index}.measurement`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                setHouseMeasurementsDebounced();
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },

                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              required
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="Tara"
      >
        <Controller
          name={`billLadingLoads.${index}.tare`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`billLadingLoads.${index}.tare`}
              type="number"
              placeholder="Tara"
              value={value}
              onChange={(e) => {
                setValue(`billLadingLoads.${index}.tare`, +e.target.value, {
                  shouldDirty: true,
                });
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
            />
          )}
        />
      </CustomInputWrapper>

      {isCargoDangerous && (
        <>
          <CustomInputWrapper
            xs={1 + extraSpaceWhenCargoIsNotDangerous}
            title="Classe"
            tooltip="Classe mercadoria perigosa"
            required
          >
            <Controller
              name={`billLadingLoads.${index}.dangerousGoodsClass`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`billLadingLoads.${index}.dangerousGoodsClass`}
                  placeholder="Classe"
                  value={value?.toString() ?? ""}
                  onChange={(e) => {
                    setValue(
                      `billLadingLoads.${index}.dangerousGoodsClass`,
                      e.target.value,
                      {shouldDirty: true},
                    );
                  }}
                  size="small"
                  required
                />
              )}
            />
          </CustomInputWrapper>

          <CustomInputWrapper
            xs={1}
            title="Cód."
            tooltip="Cód. mercadoria perigosa"
            required
          >
            <Controller
              name={`billLadingLoads.${index}.dangerousGoodsCode`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`billLadingLoads.${index}.dangerousGoodsCode`}
                  placeholder="Código"
                  value={value?.toString() ?? ""}
                  onChange={(e) => {
                    setValue(
                      `billLadingLoads.${index}.dangerousGoodsCode`,
                      e.target.value,
                      {shouldDirty: true},
                    );
                  }}
                  size="small"
                  required
                />
              )}
            />
          </CustomInputWrapper>
        </>
      )}

      <Grid
        size={0.2}
        display="flex"
        alignItems="end"
        justifyContent="flex-start"
      >
        <CustomIconButton
          iconName="delete_sweep"
          tooltipText="Limpar dados"
          disabled={isCloningItem}
          sx={{
            pr: 0,
            ":hover": {
              color: "red",
            },
          }}
          onClick={() => {
            handleDeleteLogisticBillLading(field, index);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CargoItem;
