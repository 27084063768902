/* eslint-disable react-hooks/exhaustive-deps */
import InfoIcon from "@mui/icons-material/Info";
import CustomModal from "components/CustomModal";
import pxToRem from "hooks/usePxToRem";
import {CustomButton, CustomInput, CustomInputWrapper} from "ui";
import {
  FieldsToFill,
  MultiPathList,
  SelectButton,
  EmailPreview,
} from "./components";
import {DefaultModalFooter} from "components/CustomModal/components";
import {
  FinishInBatch,
  GetTaskToFinish,
  PostActivityBatchFinish,
  PostTaskFinish,
  SendEmailFromActivity,
} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import ModalActivityCompletionInfo from "components/ModalActivityCompletionInfo";
import useToast from "hooks/useToast";
import useLoading from "hooks/useLoading";
import {
  EnumKeyStatus,
  FinishActivityPayload,
  Task,
} from "./CompleteActivity.types";
import {downloadDocumentFromUrl} from "util/downloadDocumentFromUrl";
import useAlertMessage from "hooks/useAlertMessage";
import {PostProcessFile} from "services/api_v2/Processes/Processes.service";
import ConfirmModal from "components/ConfirmModal";
import {mountEmailHtml} from "util/mountEmailHtml";
import {formatFieldIfDate} from "./util/formatDateField";
import {splitAndRemoveSpacesFromString} from "../../util/splitAndRemoveSpacesFromString";
import {ChangeActivityStatusById} from "services/api_v2/ActivitiesHistory/activitiesHistory.service";
import {ActivityStatus} from "util/globalEnums";
import {AttachFiles} from "components";
import {
  Tooltip,
  Chip,
  FormControl,
  DialogContent,
  Autocomplete,
  TextField,
  Typography,
  Divider,
  ToggleButton,
  Icon,
  useTheme,
} from "@mui/material";
import {Grid} from "@mui/system";
import {
  Dispatch,
  SetStateAction,
  useState,
  createRef,
  useEffect,
  useRef,
} from "react";
import {MRT_RowSelectionState} from "material-react-table";
import {AxiosError} from "axios";
import {Link} from "react-router-dom";
import usePostponePopper from "views/Activities/usePostponePopper";
import {PostPoneActivityPopper} from "views/Activities/components";
import {getClientClassification} from "util/getClientClassification";

interface ModalCompleteActivityProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  processNumber: string;
  processAndGroupActivityId: {
    processId: string;
    groupActivityId: string;
  };
  firstTaskData: Task;
  getActivityData: () => Promise<void>;
  activitiesToCompleteInBatch: {id: number; processNumber: string}[];
  setRowSelection: Dispatch<SetStateAction<MRT_RowSelectionState>>;
  shouldGoToNextTask?: boolean;
}

const ModalCompleteActivity = (props: ModalCompleteActivityProps) => {
  const {
    isOpen,
    setIsOpen,
    processNumber,
    firstTaskData,
    getActivityData,
    activitiesToCompleteInBatch,
    setRowSelection,
    shouldGoToNextTask = true,
  } = props;
  const theme = useTheme();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();

  const [emailContent, setEmailContent] = useState("");

  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [nextTask, setNextTask] = useState<{id: number; name: string} | null>(
    null,
  );

  const editorRef = createRef<HTMLDivElement>();

  const defaultHeight = pxToRem(415);

  const [emailData, setEmailData] = useState({
    subject: "",
    to: [] as string[],
    cc: [] as string[],
    bcc: [] as string[],
    attachment: [] as {name: string; url: string; id: string}[],
  });
  const [modalFinishedTaskIsOpen, setModalFinishedTaskIsOpen] = useState(false);

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [taskData, setTaskData] = useState<Task>({} as Task);
  const [idAttachToRemove, setIdAttachToRemove] = useState("");

  const handleConfirmRemoveAttach = (id: string) => {
    setConfirmModalIsOpen(true);
    setIdAttachToRemove(id);
  };

  const getTask = async (activityId: number) => {
    try {
      setLoading(true);

      const response = await GetTaskToFinish(activityId);
      setTaskData({
        ...response?.data?.activity,
        files: [],
        clientClassification: getClientClassification(
          response?.data?.activity?.client?.classifications,
          response?.data?.activity?.process?.operation,
          response?.data?.activity?.process?.modality,
        )?.classification?.name,
        monitoredFields: response?.data?.activity?.monitoredFields?.map(
          (item) => ({
            ...item,
            value: {
              id: item.fieldValue?.id ?? null,
              label: formatFieldIfDate(item) ?? "",
            },
            uiComponentChecked:
              item.tableName === "ui_component" ? false : null,
          }),
        ),
      });
      setNextTask(null);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTaskData(firstTaskData);
  }, []);

  const formRef = useRef<HTMLFormElement>(null);

  const handleFilesUpload = async () => {
    for await (const taskFile of taskData.files) {
      const formData = new FormData();

      formData.append("file", taskFile.file);
      formData.append(
        "process",
        JSON.stringify({
          description: taskFile?.file?.name,
          idGroupFile: taskFile?.idGroupFile,
          idProcess: taskData?.process?.id,
        }),
      );
      try {
        await PostProcessFile(formData);
      } catch (error: any) {
        const err = error as AxiosError;
        showAlert(err?.response?.data?.message);
        console.error(error);
        throw new Error("Algum dos arquivos deu erro");
      }
    }
  };

  const handleFinishActivitiesInBatch = async (payload: FinishInBatch) => {
    try {
      setLoading(true);

      if (taskData.files.length > 0) {
        await handleFilesUpload();
      }

      const response = await PostActivityBatchFinish(payload);

      setNextTask(null);
      handleCloseAllModals();
      setRowSelection({});

      showToast(response?.data?.message);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFinishTask = async (payload: FinishActivityPayload) => {
    try {
      setLoading(true);

      const response = await PostTaskFinish(payload);

      if (response?.data?.email) {
        const info = response?.data?.info;
        setEmailData({
          subject: info?.subject,
          to: info?.to,
          cc: info?.cc,
          bcc: info?.bcc,
          attachment:
            response?.data?.attachment.map((item) => ({
              url: item?.url,
              name: item?.name,
              id: crypto.randomUUID(),
            })) ?? [],
        });
        setEmailContent(response?.data?.email.replace(/\\/g, ""));
        setShowEmailPreview(true);

        if (response?.data?.nextActivity?.id && shouldGoToNextTask) {
          setNextTask({
            id: response?.data?.nextActivity?.id,
            name: response?.data?.nextActivity?.name,
          });
        }

        return;
      }

      if (response?.data?.nextActivity?.id && shouldGoToNextTask) {
        setNextTask({
          id: response?.data?.nextActivity?.id,
          name: response?.data?.nextActivity?.name,
        });
        setModalFinishedTaskIsOpen(true);
      } else {
        setNextTask(null);
        handleCloseAllModals();
      }

      showToast(response?.data?.message);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async (payload: FinishActivityPayload) => {
    if (taskData.files?.length > 0) {
      try {
        setLoading(true);
        await handleFilesUpload();
        await handleFinishTask(payload);
      } catch (error: any) {
        showAlert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      await handleFinishTask(payload);
    }
  };

  const handleFinishActivity = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const notReadOnlyFields = taskData?.monitoredFields?.filter(
      (item) => !item.isReadOnly,
    );

    const notReadOnlyOrNullFields = notReadOnlyFields?.filter(
      (item) => item.id !== null,
    );

    const returnArrayOrUndefined = (arr) => {
      return arr === null ? undefined : arr;
    };

    if (taskData?.isMultiplePath) {
      const dataToSend = {
        id: taskData?.id,
        idNewActivity: taskData?.idSelectedPath,

        updatedField:
          returnArrayOrUndefined(notReadOnlyOrNullFields)?.map((item) => ({
            id: item.id,
            value:
              item.columnType === "select"
                ? item.value?.id.toString()
                : item.value?.label,
          })) ?? [],
        activityStatus: "Concluída",
      };
      await handleSend(dataToSend);
      return;
    }

    if (
      taskData?.isCompletedInBatch &&
      activitiesToCompleteInBatch?.length > 1
    ) {
      const dataToSend = {
        activityStatus: "Concluída",
        activities: activitiesToCompleteInBatch.map((a) => a.id),
        updatedFields:
          returnArrayOrUndefined(notReadOnlyOrNullFields)?.map((item) => ({
            id: item.id,
            value:
              item.columnType === "select"
                ? item.value?.id?.toString()
                : item.value?.label,
          })) ?? [],
      };

      handleFinishActivitiesInBatch(dataToSend);
      return;
    }

    const dataToSend = {
      id: taskData?.id,
      activityStatus: "Concluída",
      updatedField:
        returnArrayOrUndefined(notReadOnlyOrNullFields)?.map((item) => ({
          id: item.id,
          value:
            item.columnType === "select"
              ? item.value?.id?.toString()
              : item.value?.label,
        })) ?? [],
    };
    await handleSend(dataToSend);
  };

  //essa função é usada somente para os status diferentes de "concluída"
  const handleUpdateActivityStatus = async (data: {
    activityId: number;
    activityStatus: EnumKeyStatus;
  }) => {
    try {
      setLoading(true);
      await ChangeActivityStatusById(
        data.activityId,
        ActivityStatus[data.activityStatus],
      );
      showToast("Status da atividade alterado com sucesso!");
    } catch (error: any) {
      showAlert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeActivityStatusWithoutCompleting = async (
    e: React.FormEvent<HTMLFormElement>,
    status: EnumKeyStatus,
  ) => {
    e.preventDefault();

    if (
      taskData?.isCompletedInBatch &&
      activitiesToCompleteInBatch?.length > 1
    ) {
      for (const item of activitiesToCompleteInBatch) {
        const dataToSend = {
          activityId: item.id,
          activityStatus: status,
        };
        await handleUpdateActivityStatus(dataToSend);
      }
      setNextTask(null);
      handleCloseAllModals();

      return;
    }

    const dataToSend = {
      activityId: taskData?.id,
      activityStatus: status,
    };
    await handleUpdateActivityStatus(dataToSend);
    setNextTask(null);
    handleCloseAllModals();
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleSendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);

      const bodyToSend = {
        idActivity: taskData?.id ?? 0,
        info: {
          subject: emailData?.subject,
          to: emailData?.to ?? [],
          cc: emailData?.cc ?? [],
          bcc: emailData?.bcc ?? [],
        },
        email: mountEmailHtml(editorRef.current?.innerHTML, emailContent),
        attachment: emailData?.attachment?.map((item) => ({
          id: item?.id,
          url: item?.url,
          name: item?.name,
        })),
      };
      await SendEmailFromActivity(bodyToSend);
      showToast("Email enviado com sucesso!", "success");
      if (nextTask) {
        setModalFinishedTaskIsOpen(true);
      } else {
        handleCloseAllModals();

        await getActivityData();
      }

      setShowEmailPreview(false);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAutoComplete = (_: unknown, newValue, field: string) => {
    const newValues =
      splitAndRemoveSpacesFromString(newValue[newValue.length - 1]) ?? [];
    const fullArray = [...newValue.slice(0, -1), ...newValues];

    setEmailData((prev) => ({...prev, [field]: fullArray}));
  };

  const handleChangeSubject = (_: unknown, newValue, field: string) => {
    setEmailData((prev) => ({...prev, [field]: newValue}));
  };

  const handleNextActivity = async () => {
    if (nextTask !== null) {
      await getTask(nextTask?.id);
    }
  };

  const handleCloseCompleteActivityModal = async () => {
    if (showEmailPreview) {
      setShowEmailPreview(false);
      if (nextTask) {
        setModalFinishedTaskIsOpen(true);
        return;
      } else {
        setNextTask(null);
        setModalFinishedTaskIsOpen(false);
      }
    }
    setIsOpen(false);
    await getActivityData();
  };

  const handleCloseAllModals = () => {
    setModalFinishedTaskIsOpen(false);
    handleCloseCompleteActivityModal();
  };

  const setValidation = () => {
    const someUiComponentIsntChecked = taskData?.monitoredFields?.some(
      (item) => item.uiComponentChecked === false,
    );

    const numberOfMonitoredFieldsFile =
      taskData?.monitoredFields?.filter((item) => item.tableName === "files")
        ?.length ?? 0;

    const numberOfSelectedFiles = taskData.files?.length;

    const someFileIsntSelected =
      numberOfMonitoredFieldsFile > numberOfSelectedFiles;

    const multiPathIsntSelected =
      taskData.isMultiplePath && !taskData.idSelectedPath;

    const shouldDisable =
      someUiComponentIsntChecked ||
      someFileIsntSelected ||
      multiPathIsntSelected;
    return shouldDisable;
  };

  useEffect(() => {
    const shouldDisable = setValidation();
    setIsSubmitDisabled(shouldDisable);
  }, [taskData]);

  const handleDeleteAttachment = (id: string) => {
    setEmailData((prev) => ({
      ...prev,
      attachment: emailData?.attachment?.filter((item) => item?.id !== id),
    }));
    setConfirmModalIsOpen(false);
  };

  const getSizeOfModal = (showEmailPreview: boolean, taskData: Task) => {
    if (showEmailPreview) {
      return "lg";
    } else if (
      taskData?.monitoredFields?.some(
        (item) => item?.isReadOnly && item?.tableName !== "ui_component",
      ) ||
      taskData?.isMultiplePath ||
      taskData?.monitoredFields?.some((item) => item?.tableName === "files")
    ) {
      return "sm";
    } else {
      return "xs";
    }
  };

  const {
    activityIdToPostpone,
    anchorEl,
    handleClosePostponeActivityPopper,
    handlePostponeActivity,
    postponePopperIsOpen,
    processNumberToPostpone,
  } = usePostponePopper();

  return (
    <>
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        title="Remover anexo"
        subtitle="Essa ação não pode ser desfeita!"
        confirmButtonFn={() => handleDeleteAttachment(idAttachToRemove)}
        onClose={() => setConfirmModalIsOpen(false)}
      />

      <PostPoneActivityPopper
        anchorEl={anchorEl}
        postponePopperIsOpen={postponePopperIsOpen}
        processNumberToPostpone={processNumberToPostpone}
        processIdToPostpone={activityIdToPostpone}
        handleClose={handleClosePostponeActivityPopper}
        onClose={handleCloseCompleteActivityModal}
      />

      {!!nextTask && (
        <ModalActivityCompletionInfo
          isOpen={modalFinishedTaskIsOpen}
          setIsOpen={setModalFinishedTaskIsOpen}
          activityName={taskData?.name}
          handleFinishTask={handleNextActivity}
          processNumber={processNumber}
          hasNextActivity={!!nextTask}
          nextActivityName={nextTask?.name}
          onCloseFinishTask={handleCloseAllModals}
        />
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={handleCloseCompleteActivityModal}
        title="Concluir atividade"
        titleIcon="fact_check"
        subtitle={taskData?.name}
        classificationBadge={taskData?.clientClassification}
        dividerBadge={
          <Tooltip
            title="Acessar informações do processo"
            arrow
            placement="left"
          >
            <Link
              target="_blank"
              to={`/process-details/${taskData?.process?.id}`}
            >
              <Chip label={processNumber} color="primary" />
            </Link>
          </Tooltip>
        }
        maxWidth={getSizeOfModal(showEmailPreview, taskData)}
        titleEndIcon={
          taskData?.instructions ? (
            <Tooltip title={taskData?.instructions}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          ) : (
            <></>
          )
        }
        sx={{zIndex: 1000}}
      >
        <FormControl
          component="form"
          onSubmit={(e) => {
            showEmailPreview ? handleSendEmail(e) : handleFinishActivity(e);
          }}
          id="mainForm"
          ref={formRef}
        >
          <Grid container>
            <Grid size={showEmailPreview ? 4 : 12} sx={{pb: pxToRem(20)}}>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: pxToRem(12),
                  paddingTop: pxToRem(7),
                  paddingBottom: pxToRem(20),
                  maxHeight: defaultHeight,
                }}
              >
                <Grid
                  container
                  rowGap={pxToRem(8)}
                  sx={{
                    maxHeight: defaultHeight,
                    overflowY: "unset",
                  }}
                >
                  {taskData?.isMultiplePath && !showEmailPreview && (
                    <MultiPathList
                      taskData={taskData}
                      setTaskData={setTaskData}
                    />
                  )}
                  {!showEmailPreview && (
                    <FieldsToFill
                      taskData={taskData}
                      handleBoxScroll={() => void 0}
                      setTaskData={setTaskData}
                    />
                  )}

                  {showEmailPreview && (
                    <>
                      <CustomInputWrapper title="Assunto" xs={12}>
                        <CustomInput
                          name="subject"
                          value={emailData?.subject ?? ""}
                          size="small"
                          required
                          onChange={(e) =>
                            handleChangeSubject(e, e.target.value, "subject")
                          }
                        />
                      </CustomInputWrapper>
                      <CustomInputWrapper title="Para" xs={12}>
                        <Autocomplete
                          multiple
                          size="small"
                          freeSolo
                          autoSelect
                          value={emailData?.to ?? []}
                          limitTags={5}
                          onChange={(e, newValue) =>
                            handleAutoComplete(e, newValue, "to")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={emailData?.to?.length === 0}
                            />
                          )}
                          options={[]}
                        />
                      </CustomInputWrapper>
                      <CustomInputWrapper title="CC" xs={12}>
                        <Autocomplete
                          multiple
                          size="small"
                          freeSolo
                          autoSelect
                          value={emailData?.cc ?? []}
                          onChange={(e, newValue) =>
                            handleAutoComplete(e, newValue, "cc")
                          }
                          renderInput={(params) => <TextField {...params} />}
                          options={[]}
                        />
                      </CustomInputWrapper>
                      <CustomInputWrapper title="BCC" xs={12}>
                        <Autocomplete
                          multiple
                          size="small"
                          freeSolo
                          autoSelect
                          value={emailData?.bcc ?? []}
                          onChange={(e, newValue) =>
                            handleAutoComplete(e, newValue, "bcc")
                          }
                          renderInput={(params) => <TextField {...params} />}
                          options={[]}
                        />
                      </CustomInputWrapper>

                      <CustomInputWrapper title="Anexos" xs={12}>
                        <AttachFiles
                          emailData={emailData}
                          setEmailData={setEmailData}
                          processId={taskData?.process?.id}
                        />
                        {emailData?.attachment.length === 0 && (
                          <Typography variant="subtitle3">
                            Sem anexos
                          </Typography>
                        )}
                        <Grid container sx={{display: "flex"}}>
                          {emailData?.attachment.map((item) => (
                            <Grid
                              size={12}
                              key={item?.id ?? item?.name}
                              sx={{marginBottom: pxToRem(8)}}
                            >
                              <Tooltip title={item?.name} arrow placement="top">
                                <Chip
                                  label={item?.name}
                                  size="medium"
                                  variant="filled"
                                  clickable
                                  onClick={() =>
                                    downloadDocumentFromUrl(
                                      item?.url,
                                      item?.name,
                                    )
                                  }
                                  onDelete={() =>
                                    handleConfirmRemoveAttach(item?.id)
                                  }
                                  sx={{
                                    backgroundColor: theme.palette.primary[50],
                                    color:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.primary[600]
                                        : theme.palette.primary[700],
                                    ":hover": {
                                      backgroundColor:
                                        theme.palette.primary[50],
                                      opacity: 0.9,
                                    },
                                    "& .MuiChip-deleteIcon": {
                                      marginLeft: pxToRem(10),
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      </CustomInputWrapper>
                    </>
                  )}
                </Grid>
              </DialogContent>
            </Grid>
            {showEmailPreview && (
              <Grid
                size={0.5}
                display="flex"
                justifyContent="center"
                p={`${pxToRem(10)} 0`}
              >
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid
              size="grow"
              sx={{
                height: defaultHeight,
                overflow: "hidden",
                display: showEmailPreview ? "unset" : "none",
                padding: `${pxToRem(16)} 0 ${pxToRem(16)} 0`,
              }}
            >
              <EmailPreview emailContent={emailContent} editorRef={editorRef} />
            </Grid>
            <DefaultModalFooter>
              <Grid size="grow">
                <ToggleButton
                  value="check"
                  onClick={(e) =>
                    handlePostponeActivity(
                      taskData?.id,
                      taskData?.process?.processNumber ?? "",
                      e,
                    )
                  }
                  sx={{
                    height: `${pxToRem(42.5)}`,
                    display: showEmailPreview ? "none" : undefined,
                  }}
                >
                  <Tooltip title="Adiar vencimento" placement="top" arrow>
                    <Icon>update</Icon>
                  </Tooltip>
                </ToggleButton>
              </Grid>
              <Grid
                container
                spacing={1}
                sx={{display: "flex", justifyContent: "flex-end"}}
              >
                <Grid>
                  <CustomButton
                    variant="outlined"
                    onClickFn={() => {
                      handleCloseAllModals();
                    }}
                  >
                    {showEmailPreview ? "Não Enviar" : "Cancelar"}
                  </CustomButton>
                </Grid>
                <Grid>
                  <SelectButton
                    handleUpdateActivityStatus={
                      handleChangeActivityStatusWithoutCompleting
                    }
                    isSelect={!showEmailPreview}
                    formRef={formRef}
                    disabled={showEmailPreview ? false : isSubmitDisabled}
                  >
                    {showEmailPreview ? "Enviar email" : "Concluir atividade"}
                  </SelectButton>
                </Grid>
              </Grid>
            </DefaultModalFooter>
          </Grid>
        </FormControl>
      </CustomModal>
    </>
  );
};

export default ModalCompleteActivity;
