import {Grid} from "@mui/system";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {GetPackagesList} from "services/api_v2/Processes/Processes.service";
import {
  CustomInputWrapper,
  CustomAutocomplete,
  CustomInput,
  CustomIconButton,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

type Props = {
  index: number;
  field: any;
  handleDeleteLogisticPackage: (field: any, index: number) => Promise<void>;
  loadIndex: number;
  setHouseMeasurements: () => void;
};

const ActivityPackageItem = (props: Props) => {
  const {handleDeleteLogisticPackage, index, field, setHouseMeasurements} =
    props;
  const {control, setValue, watch} = useFormContext();

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackagesList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const [tempData, setTempData] = useState<
    | {
        cubicMeters?: number;
        cubeWeight?: number;
        consideredWeight?: number;
      }[]
    | null
    | undefined
  >(null);

  const handleChangeTempMeasureValue = (
    value: number | undefined,
    index: number,
  ) => {
    const newTempValue = tempData?.map((item, i) => {
      if (i === index) {
        return {...item, cubicMeters: value};
      } else {
        return item;
      }
    });
    return newTempValue;
  };

  const handleChangeTempLoadsValue = (
    data: {[key: string]: number | undefined},
    index: number,
  ) => {
    const newTempValue = tempData?.map((item, i) => {
      if (i === index) {
        return {...item, ...data};
      } else {
        return item;
      }
    });
    return newTempValue ?? [data];
  };

  const calculateVolume = (index: number) => {
    const widthInMeters = (watch(`processPackages.${index}.width`) ?? 0) / 100;
    const lengthInMeters =
      (watch(`processPackages.${index}.length`) ?? 0) / 100;
    const heightInMeters =
      (watch(`processPackages.${index}.height`) ?? 0) / 100;

    const volume = +(widthInMeters * lengthInMeters * heightInMeters).toFixed(
      3,
    );
    const newTempValue = handleChangeTempMeasureValue(volume, index);
    setTempData(newTempValue);
    setValue(`processPackages.${index}.cubicMeters`, volume, {
      shouldDirty: true,
    });
    calculateWeight(index);
  };

  const calculateVolumeDebounced = useDebounceCallback(calculateVolume, 1000);

  const calculateWeight = (index: number) => {
    const loadData = calcConsideredWeight(
      watch(`processPackages.${index}.cubicMeters`) ?? 0,
      watch(`processPackages.${index}.grossWeight`) ?? 0,
    );

    const oldTempData = tempData ?? [];
    oldTempData[index] = {...oldTempData[index], ...loadData};
    setTempData(oldTempData);

    setTempData(oldTempData);
    setValue(
      `processPackages.${index}.consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(`processPackages.${index}.cubedWeight`, loadData.cubeWeight, {
      shouldDirty: true,
    });
    setHouseMeasurementsDebounced();
  };

  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );
  return (
    <Grid key={field.idForm} container columnSpacing={pxToRem(2)}>
      <CustomInputWrapper md={2} title={`Pacote ${index + 1}`} required>
        <Controller
          name={`processPackages.${index}.package`}
          control={control}
          render={({field: {value}}) => (
            <CustomAutocomplete
              name={`processPackages.${index}.package`}
              placeholder="Pacote"
              tooltipText={getTooltipText(value?.name)}
              loading={isLoadingPackagesOptions}
              options={mountOptions(value, packagesOptions)}
              value={value?.name ?? null}
              onOpen={() => getPackagesOptions(setPackagesOptions, 1, "")}
              onInputChange={(e, value) => {
                if (e !== null) {
                  setSearch(value);
                  getPackagesOptions(setPackagesOptions, 1, search);
                }
              }}
              getNextPage={() => {
                onBottomScrollPackages(setPackagesOptions, search);
              }}
              onChange={(e, value) => {
                handleChangeAutocomplete(
                  e,
                  value,
                  `processPackages.${index}.package`,
                );
              }}
              isOptionEqualToValue={(option, value) => value === option?.label}
              required
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper md={1} title="Quant." tooltip="Quantidade" required>
        <Controller
          name={`processPackages.${index}.quantity`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.quantity`}
              type="number"
              placeholder="Quantidade"
              value={value ?? 0}
              onChange={(e) => {
                setValue(`processPackages.${index}.quantity`, +e.target.value, {
                  shouldDirty: true,
                });
                setHouseMeasurementsDebounced();
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
              required
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        md={1}
        title="Comprimento (cm)"
        tooltip="Comprimento (cm)"
      >
        <Controller
          name={`processPackages.${index}.length`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.length`}
              type="number"
              placeholder="Altura"
              value={value ?? 0}
              onChange={(e) => {
                setValue(`processPackages.${index}.length`, +e.target.value, {
                  shouldDirty: true,
                });
                calculateVolumeDebounced(index);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper md={1} title="Altura (cm)" tooltip="Altura (cm)">
        <Controller
          name={`processPackages.${index}.height`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.height`}
              type="number"
              placeholder="Altura"
              value={value ?? 0}
              onChange={(e) => {
                setValue(`processPackages.${index}.height`, +e.target.value, {
                  shouldDirty: true,
                });
                calculateVolumeDebounced(index);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper md={1} title="Largura (cm)" tooltip="Largura (cm)">
        <Controller
          name={`processPackages.${index}.width`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.width`}
              type="number"
              placeholder="Largura"
              value={value ?? 0}
              onChange={(e) => {
                setValue(`processPackages.${index}.width`, +e.target.value, {
                  shouldDirty: true,
                });
                calculateVolumeDebounced(index);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper md={1} title="Medidas (m³)" tooltip="Medidas (m³)">
        <Controller
          name={`processPackages.${index}.cubicMeters`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.cubicMeters`}
              type="number"
              placeholder="Medidas"
              value={tempData?.[index]?.cubicMeters ?? value ?? 0}
              onChange={(e) => {
                setValue(
                  `processPackages.${index}.cubicMeters`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                calculateWeight(index);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        md={1}
        title="Peso líquido (kg)"
        tooltip="Peso líquido (kg)"
      >
        <Controller
          name={`processPackages.${index}.netWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.netWeight`}
              type="number"
              placeholder="Peso"
              value={value ?? 0}
              onChange={(e) =>
                setValue(
                  `processPackages.${index}.netWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                )
              }
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        md={1}
        title="Peso bruto (kg)"
        tooltip="Peso bruto (kg)"
      >
        <Controller
          name={`processPackages.${index}.grossWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.grossWeight`}
              type="number"
              placeholder="Peso"
              value={value ?? 0}
              onChange={(e) => {
                setValue(
                  `processPackages.${index}.grossWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                calculateWeight(index);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        md={1.5}
        title="Peso cubado (kg)"
        tooltip="Peso cubado (kg)"
      >
        <Controller
          name={`processPackages.${index}.cubedWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.cubedWeight`}
              type="number"
              placeholder="Peso"
              value={tempData?.[index]?.cubeWeight ?? value ?? 0}
              onChange={(e) => {
                setValue(
                  `processPackages.${index}.cubedWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                const newValue = handleChangeTempLoadsValue(
                  {cubeWeight: undefined},
                  index,
                );
                calculateWeight(index);
                setTempData(newValue);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        md={1}
        title="Peso considerado (kg)"
        tooltip="Peso considerado (kg)"
      >
        <Controller
          name={`processPackages.${index}.consideredWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`processPackages.${index}.consideredWeight`}
              type="number"
              placeholder="Peso"
              value={tempData?.[index]?.consideredWeight ?? value ?? 0}
              onChange={(e) => {
                setValue(
                  `processPackages.${index}.consideredWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                const newValue = handleChangeTempLoadsValue(
                  {consideredWeight: undefined},
                  index,
                );
                calculateWeight(index);
                setTempData(newValue);
              }}
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>
      <Grid size={0.5} display="flex" alignItems="end" justifyContent="end">
        <CustomIconButton
          iconName="delete"
          onClick={() => handleDeleteLogisticPackage(field, index)}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityPackageItem;
