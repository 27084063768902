import {Grid} from "@mui/system";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import "./styles.css";

const FeatureCard = ({title, value, gain}) => {
  return (
    <div className="feature-card">
      <div className="feature-card-body p-3">
        <Grid container size={12}>
          <Grid size={8}>
            <div className="numbers">
              <p className="title">Esperado para hoje</p>
              <h5 className="value">
                $1.000.000.000,00 <span className="complement">+25%</span>
              </h5>
            </div>
          </Grid>

          <Grid size={4} style={{display: "flex", justifyContent: "end"}}>
            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
              <AttachMoneyIcon className="feature-card-icon" />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FeatureCard;
