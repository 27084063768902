/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState} from "react";
import {Typography} from "@mui/material";
import {Grid} from "@mui/system";

import {NotificationCard} from "./components";
import {format} from "date-fns";
import pxToRem from "hooks/usePxToRem";

const MyNotifications = () => {
  const [listNotify, setListNotify] = useState<Array<any>>([
    {
      title: "Nova mensagem",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      moment: format(new Date(), "dd/MM/yyyy - HH:mm"),
      processId: "",
      type: "chat",
      _id: "62ace9075af2b00018e01422",
      processNumber: "NEXTIM022233",
    },
    {
      title: "Aprovação de documentos",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      moment: format(new Date(), "dd/MM/yyyy - HH:mm"),
      processId: "",
      type: "other",
      _id: "62ace9075af2b00018e01422",
      processNumber: "NEXTIM022233",
    },
  ]);

  return (
    <Grid
      container
      sx={{
        padding: pxToRem(16),
        gap: pxToRem(8),
        minWidth: pxToRem(320),
        maxWidth: pxToRem(380),
      }}
    >
      <Grid size={12}>
        <Typography variant="h5" sx={{fontSize: pxToRem(18), fontWeight: 600}}>
          Notificações
        </Typography>
      </Grid>

      {listNotify.length > 0 ? (
        listNotify.map((item: any) => (
          <NotificationCard
            key={item?.title}
            item={item}
            listNotify={listNotify}
            setListNotify={setListNotify}
          />
        ))
      ) : (
        <Grid size={12} sx={{padding: pxToRem(20), textAlign: "center"}}>
          <Typography variant="body2">Sem notificações</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MyNotifications;
