/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import {Provider as StoreProvider} from "react-redux";
import {CircularProgress} from "@mui/material";
import configureStore from "./store/configureStore";
import routes from "./routes";
import {PersistGate} from "redux-persist/integration/react";

const {store, persistor} = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback={<CircularProgress color="secondary" />}>
            {renderRoutes(routes)}
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
