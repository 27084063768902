import {Input, SxProps, Theme, useTheme} from "@mui/material";
import {Grid} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import InputMask from "react-input-mask";

type SplitInputProps = {
  widthInPxEachInput?: string;
  firsInputValue: unknown;
  firstInputOnChange?: () => void;
  customFocus?: Theme;
  value?: string;
  onChange?: (value: string) => void;
  height?: string;
  sx?: SxProps;
};

const SplitInput = (props: SplitInputProps) => {
  const theme = useTheme();
  const {
    widthInPxEachInput: width,
    firsInputValue,
    firstInputOnChange,
    customFocus,
    value = "",
    onChange,
    height,
    sx,
  } = props;

  const [time, setTime] = useState(value);
  const startsWithTwo = time.startsWith("2");

  const handleInput = ({target: {value}}) => {
    setTime(value);
    if (onChange) {
      onChange(value);
    }
  };

  const mask = [/[0-2]/, startsWithTwo ? /[0-3]/ : /\d/, ":", /[0-5]/, /\d/];

  const checkCustomFocus = JSON.stringify(
    !customFocus ? theme.palette.grey[300] : customFocus,
  );

  return (
    <Grid container size={12}>
      <Grid
        size={12}
        sx={{
          "div:first-of-type.Mui-focused ~ div": {
            borderColor: theme.palette.primary[600],
          },
          "div:has(~ div:last-of-type.Mui-focused)": {
            borderColor: theme.palette.primary[600],
          },
          "div:first-of-type:hover ~ div": {
            borderColor: theme.palette.text.secondary,
          },
          "div:has(~ div:last-of-type:hover)": {
            borderColor: theme.palette.text.secondary,
          },
        }}
      >
        <Input
          disableUnderline
          value={firsInputValue}
          onChange={firstInputOnChange}
          sx={{
            ...sx,
            width: width,
            height: height,
            borderLeft: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
            borderTop: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
            borderBottom: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
            borderRadius: `${pxToRem(4)} 0 0 ${pxToRem(4)}`,
            pl: pxToRem(8),
            position: "relative",
            zIndex: 1,
            "::after": {
              content: '""',
              position: "absolute",
              height: "80%",
              width: pxToRem(1),
              backgroundColor: theme.palette.grey[300],
              top: "10%",
              right: "0",
              zIndex: 2,
            },
            "&.Mui-focused": {
              borderColor: theme.palette.primary[600],
            },
            ":hover": {
              borderColor: theme.palette.text.secondary,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: `${customFocus}`,
              borderWidth: `${customFocus}`,
            },
            borderColor: `${checkCustomFocus}`,
          }}
        />

        <InputMask
          mask={mask}
          value={time}
          onChange={handleInput}
          alwaysShowMask
        >
          <Input
            disableUnderline
            sx={{
              ...sx,
              width: width,
              height: height,
              borderRight: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
              borderTop: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
              borderBottom: `${pxToRem(1)} solid ${theme.palette.grey[300]}`,
              borderRadius: `0 ${pxToRem(4)}  ${pxToRem(4)} 0`,
              borderColor: `${checkCustomFocus}`,

              pl: pxToRem(8),
              "&.Mui-focused": {
                borderColor: theme.palette.primary[600],
              },
              ":hover": {
                borderColor: theme.palette.text.secondary,
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${customFocus}`,
                borderWidth: `${customFocus}`,
              },
            }}
          />
        </InputMask>
      </Grid>
    </Grid>
  );
};

export default SplitInput;
