import {Fragment, ReactNode} from "react";
import {Divider} from "@mui/material";
import {Grid} from "@mui/system";

type Props = {
  children: ReactNode;
};

const ModalWrapperFooter = (props: Props) => {
  const {children} = props;
  return (
    <Fragment>
      <Grid container>
        <Grid size={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container p="20px" spacing="8px">
        {children}
      </Grid>
    </Fragment>
  );
};

export default ModalWrapperFooter;
