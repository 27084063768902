import { Grid } from "@mui/system";
import { FormControl, Typography, RadioGroup, FormControlLabel, Radio, useTheme } from "@mui/material";
import {Stack} from "@mui/system";
import pxToRem from "hooks/usePxToRem";

const LinkOptions = () => {
  const theme = useTheme();
  return (
    <Grid container mt={pxToRem(20)}>
      <FormControl>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={pxToRem(24)}
        >
          <Typography
            variant="subtitle1"
            component="strong"
            sx={{
              position: "relative",
              fontWeight: 600,
              paddingLeft: 0,

              "&::after": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: pxToRem(-4),
                height: pxToRem(4),
                width: pxToRem(36),
                backgroundColor: theme.palette.primary.main,
                borderRadius: pxToRem(30),
              },
            }}
          >
            Lançar para:
          </Typography>
        </Stack>
        <Grid container>
          <RadioGroup row>
            <FormControlLabel
              value="process"
              control={<Radio checked={true} />}
              label="processo"
            />
          </RadioGroup>
        </Grid>
      </FormControl>
    </Grid>
  );
};

export default LinkOptions;
