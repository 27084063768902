import {Typography} from "@mui/material";
import {Grid} from "@mui/system";
import {Box} from "@mui/system";
import {CopyToClipboardIcon, CustomModal} from "components";
import {DefaultModalFooter} from "components/CustomModal/components";
import pxToRem from "hooks/usePxToRem";
import {CustomButton} from "ui";

type Props = {
  correspondenceText: string;
  isOpen: boolean;
  onClose: () => void;
  correspondenceTitle: string;
};

const CorrespondenceModal = (props: Props) => {
  const {correspondenceText, isOpen, onClose, correspondenceTitle} = props;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Correspondência - ${correspondenceTitle}`}
      titleIcon="markunread_box"
      maxWidth="xs"
    >
      <Box
        sx={{
          m: pxToRem(20),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: pxToRem(8),
        }}
      >
        <CopyToClipboardIcon
          textToBeCopied={correspondenceText}
          iconSize={24}
          toastText="Correspondência copiada!"
          tooltipPlacement="top"
          tooltipTitle="Copiar"
        />
        <Typography
          component={"pre"}
          sx={{
            overflowX: "auto",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {correspondenceText}
        </Typography>
      </Box>
      <DefaultModalFooter>
        <Grid container size={12} display="flex" justifyContent="flex-end">
          <Grid size={4}>
            <CustomButton size="small" fullWidth onClickFn={onClose}>
              Fechar
            </CustomButton>
          </Grid>
        </Grid>
      </DefaultModalFooter>
    </CustomModal>
  );
};

export default CorrespondenceModal;
