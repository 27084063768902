import React, {Fragment, useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Stack, Typography} from "@mui/material";
import {Grid} from "@mui/system";

import {
  CustomInputWrapper,
  CustomInput,
  CustomToggle,
  CustomSelect,
  CustomAutocomplete,
  CustomAutoComplete,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

import {DefaultCardStructure} from "components";
import {TypeLoad, WoodPackage} from "util/globalEnums";
import {useFormContext, Controller, useFieldArray} from "react-hook-form";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {
  GetCommoditiesList,
  GetCurrenciesList,
} from "services/api_v2/Processes/Processes.service";
import {
  GetPersonsByTypes,
  PersonTypes,
} from "services/api_v2/Persons/Persons.service";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

const GeneralLoadInfo = () => {
  const {control, watch} = useFormContext();

  const {fields} = useFieldArray({
    name: "processLoads",
  });

  const [insurerOptions, setInsurerOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const [currenciesOptions, setCurrenciesOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [commoditiesOptions, setCommoditiesOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [searchFields, setSearchFields] = useState({
    currencies: "",
    commodities: "",
    insurer: "",
  });

  const {
    getOptions: getCurrenciesOptions,
    onBottomScroll: onBottomScrollCurrencies,
    isLoadingOptions: isLoadingCurrenciesOptions,
    pageState: currenciesPageState,
  } = useHandleFieldLists(GetCurrenciesList);

  const {
    getOptions: getCommoditiesOptions,
    onBottomScroll: onBottomScrollCommodities,
    isLoadingOptions: isLoadingCommoditiesOptions,
  } = useHandleFieldLists(GetCommoditiesList);

  const {
    getOptions: getInsurerOptions,
    onBottomScroll: onBottomScrollInsurer,
    isLoadingOptions: isLoadingInsurerOptions,
    pageState: insurerPageState,
  } = useHandleFieldLists(GetPersonsByTypes, null, [PersonTypes.insurer]);

  return (
    <DefaultCardStructure
      title="Carga"
      linkId="load"
      hasDivider={true}
      isSubcard={false}
    >
      {fields?.length === 0 && (
        <Typography>Não existem cargas no processo.</Typography>
      )}
      {fields?.map((field, index) => (
        <React.Fragment key={field.id}>
          <Grid
            container
            mb={pxToRem(20)}
            rowSpacing={pxToRem(16)}
            columnSpacing={pxToRem(8)}
            key={field.id}
          >
            <CustomInputWrapper md={2.4} title="Tipo">
              <Controller
                name={`processLoads[${index}].typeLoad`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`processLoads[${index}].typeLoad`}
                    value={value}
                    onChange={onChange}
                    content={Object.values(TypeLoad)}
                    size="small"
                  />
                )}
              />

              <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={pxToRem(20)}
                sx={{
                  marginTop: pxToRem(-4),
                  maxHeight: pxToRem(32),
                  ml: pxToRem(5),
                }}
              >
                <Controller
                  name={`processLoads[${index}].cargoDangerous`}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <CustomToggle
                      name={`processLoads[${index}].cargoDangerous`}
                      label="Perigosa"
                      labelPosition="end"
                      onToggleSwitch={onChange}
                      isChecked={value}
                    />
                  )}
                />
                <Controller
                  name={`processLoads[${index}].isInsurance`}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <CustomToggle
                      name={`processLoads[${index}].isInsurance`}
                      label="Seguro"
                      labelPosition="end"
                      onToggleSwitch={onChange}
                      isChecked={value}
                    />
                  )}
                />
              </Stack>
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Mercadoria" isDisabled>
              <Controller
                name={`processLoads[${index}].commodity`}
                control={control}
                render={({field: {value}}) => (
                  <CustomAutocomplete
                    name={`processLoads[${index}].commodity`}
                    placeholder="Mercadoria"
                    disabled
                    tooltipText={getTooltipText(value?.name)}
                    loading={isLoadingCommoditiesOptions}
                    options={mountOptions(value, commoditiesOptions)}
                    value={value?.name ?? ""}
                    onOpen={
                      commoditiesOptions.length > 0
                        ? undefined
                        : () =>
                            getCommoditiesOptions(setCommoditiesOptions, 1, "")
                    }
                    onInputChange={(e, value) => {
                      if (e !== null) {
                        setSearchFields((prev) => ({
                          ...prev,
                          commodities: value,
                        }));
                        getCommoditiesOptions(setCommoditiesOptions, 1, value);
                      }
                    }}
                    getNextPage={() => {
                      onBottomScrollCommodities(
                        setCommoditiesOptions,
                        searchFields.commodities,
                      );
                    }}
                    onChange={(e, value) => {
                      handleChangeAutocomplete(
                        e,
                        value,
                        `processLoads[${index}].commodity`,
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value === option?.label
                    }
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Moeda Da Invoice" isDisabled>
              <Controller
                name={`processLoads[${index}].currencyCommodity`}
                control={control}
                render={({field: {value}}) => (
                  <CustomAutoComplete
                    name={`processLoads[${index}].currencyCommodity`}
                    size="small"
                    placeholder="Moeda Da Invoice"
                    isDisabled
                    isLoading={isLoadingCurrenciesOptions}
                    value={value?.name ?? ""}
                    tooltipText={getTooltipText(value?.name)}
                    onChangeFn={(e, value) =>
                      handleChangeAutocomplete(
                        e,
                        value,
                        `processLoads[${index}].currencyCommodity`,
                      )
                    }
                    options={currenciesOptions}
                    onOpen={
                      currenciesOptions.length > 0
                        ? undefined
                        : () => getCurrenciesOptions(setCurrenciesOptions)
                    }
                    onInputChange={(e, value) => {
                      if (e !== null) {
                        getCurrenciesOptions(setCurrenciesOptions, 1, value);
                      }
                    }}
                    onBottomScroll={() =>
                      onBottomScrollCurrencies(setCurrenciesOptions)
                    }
                    hasMorePagesToLoad={
                      currenciesPageState.currentPage <
                      currenciesPageState.lastPage
                    }
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Valor Da Mercadoria" isDisabled>
              <Controller
                name={`processLoads[${index}].commodityValue`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomInput
                    name={`processLoads[${index}].commodityValue`}
                    value={value}
                    disabled
                    placeholder="Valor"
                    onChange={onChange}
                    size="small"
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Wood Package">
              <Controller
                name={`processLoads[${index}].woodPackage`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`processLoads[${index}].woodPackage`}
                    value={value}
                    onChange={onChange}
                    content={Object.values(WoodPackage)}
                    size="small"
                  />
                )}
              />
            </CustomInputWrapper>

            {watch(`processLoads[${index}].cargoDangerous`) && (
              <Fragment>
                <CustomInputWrapper md={2.4} title="Classe">
                  <Controller
                    name={`processLoads[${index}].dangerousClass`}
                    control={control}
                    disabled
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`processLoads[${index}].dangerousClass`}
                        value={value}
                        placeholder="Classe"
                        onChange={onChange}
                        size="small"
                      />
                    )}
                  />
                </CustomInputWrapper>

                <CustomInputWrapper md={2.4} title="Quantidade UN" isDisabled>
                  <Controller
                    name={`processLoads[${index}].dangerousQuantity`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`processLoads[${index}].dangerousQuantity`}
                        type="number"
                        value={value}
                        placeholder="Quantidade"
                        onChange={onChange}
                        size="small"
                        disabled
                      />
                    )}
                  />
                </CustomInputWrapper>
              </Fragment>
            )}

            {watch(`processLoads[${index}].isInsurance`) && (
              <Fragment>
                <CustomInputWrapper
                  md={2.4}
                  title="Importância Segurada"
                  isDisabled
                >
                  <Controller
                    name={`processLoads[${index}].insuredAmount`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`processLoads[${index}].insuredAmount`}
                        placeholder="Importância segurada"
                        value={value}
                        onChange={onChange}
                        size="small"
                        disabled
                      />
                    )}
                  />
                </CustomInputWrapper>

                <CustomInputWrapper
                  md={2.4}
                  title="Percentual Seguro"
                  isDisabled
                >
                  <Controller
                    name={`processLoads[${index}].insurancePercentage`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`processLoads[${index}].insurancePercentage`}
                        type="number"
                        placeholder="% Seguro"
                        value={value}
                        onChange={onChange}
                        size="small"
                        disabled
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Seguradora" xs={2.4}>
                  <Controller
                    name={`processLoads[${index}].insurer`}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutoComplete
                        size="small"
                        name={`processLoads[${index}].insurer`}
                        placeholder="Seguradora"
                        isLoading={isLoadingInsurerOptions}
                        value={value?.name ?? ""}
                        tooltipText={getTooltipText(value?.name)}
                        onChangeFn={(e, value) =>
                          handleChangeAutocomplete(
                            e,
                            value,
                            `processLoads[${index}].insurer`,
                          )
                        }
                        options={insurerOptions}
                        onOpen={
                          insurerOptions.length > 0
                            ? undefined
                            : () => getInsurerOptions(setInsurerOptions, 1, "")
                        }
                        onBottomScroll={() =>
                          onBottomScrollInsurer(
                            setInsurerOptions,
                            searchFields.insurer,
                          )
                        }
                        hasMorePagesToLoad={
                          insurerPageState.currentPage <
                          insurerPageState.lastPage
                        }
                        onInputChange={(e, value) => {
                          //condição pra não fazer a requisição na primeira atualização do input
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              insurer: value,
                            }));
                            getInsurerOptions(setInsurerOptions, 1, value);
                          }
                        }}
                      />
                    )}
                  />
                </CustomInputWrapper>
              </Fragment>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </DefaultCardStructure>
  );
};

export default GeneralLoadInfo;
