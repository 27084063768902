import {Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {ConfirmModal, DefaultCardStructure} from "components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {useFormContext, useFieldArray} from "react-hook-form";
import {
  DeleteLogisticPackage,
  PatchProcessById,
} from "services/api_v2/Processes/Processes.service";
import {CustomButton} from "ui";
import ModalNewPackage from "../ModalNewPackage";
import {LogisticPackages} from "views/ProcessDetails/Process.types";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";
import {PackageItem} from "./components";
import {calcConsideredWeight} from "../../utils/calculateConsideredWeight";
import {useParams} from "react-router";

type Props = {
  loadIndex: number;
};

const ProcessPackages = (props: Props) => {
  const {reset, formState, getValues, setValue, watch} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    name: "processPackages",
    keyName: "idForm",
  });
  const {id: processId} = useParams<{id: string}>();

  const handleAppendItem = () => {
    append({
      consideredWeight: 0,
      cubedWeight: 0,
      grossWeight: 0,
      height: 0,
      length: 0,
      measurement: 0,
      netWeight: 0,
      package: 0,
      quantity: 0,
      width: 0,
    });
  };
  const loadIndex = 0;
  const setHouseMeasurements = (shouldDirtyFields = true) => {
    const packages = watch("processPackages");
    const totalGrossWeight = packages.reduce(
      (acc, prev) => acc + prev.grossWeight,
      0,
    );

    const totalQuantities = packages.reduce(
      (acc, prev) => acc + prev.quantity,
      0,
    );
    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: shouldDirtyFields,
    });
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: shouldDirtyFields,
    });
    const totalMeasurements = packages.reduce(
      (acc, prev) => acc + prev.cubicMeters,
      0,
    );

    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: shouldDirtyFields,
    });

    //calcular peso considerado da carga
    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: shouldDirtyFields},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: shouldDirtyFields,
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCloseModalAfterSave = (payload: Partial<LogisticPackages>) => {
    reset(
      {
        ...formState.defaultValues,
        processPackages: [
          ...formState.defaultValues?.processPackages,
          {...payload},
        ],
      },
      {keepDefaultValues: false},
    );

    setModalIsOpen(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteLogisticPackage = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };

  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      const prevProcessPackages = formState.defaultValues?.processPackages;

      prevProcessPackages.splice(itemToDelete?.index, 1);

      await DeleteLogisticPackage(itemToDelete.itemId);
      remove(itemToDelete.index);

      const newProcessPackages = getValues("processPackages").filter(
        (item) => item !== itemToDelete.itemId,
      );

      reset({
        ...formState.defaultValues,
        processPackages: newProcessPackages,
      });

      handleClose();

      setHouseMeasurements(false);
      const loadValues = getValues("processLoads");
      const newLoads = {
        logisticLoads: [
          {
            id: loadValues[0]?.id,
            quantities: loadValues[0]?.quantities,
            grossWeight: loadValues[0]?.grossWeight,
            cubicMeters: loadValues[0]?.cubicMeters,
            consideredWeight: loadValues[0]?.consideredWeight,
            cubeWeight: loadValues[0]?.cubeWeight,
          },
        ],
      };

      await PatchProcessById(+processId, newLoads);

      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  return (
    <>
      {modalIsOpen && (
        <ModalNewPackage
          isOpen={modalIsOpen}
          handleCloseModalAfterSave={handleCloseModalAfterSave}
          handleCloseModal={handleCloseModal}
        />
      )}

      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga/pacote"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - LCL"
        hasDivider={true}
        isSubcard
        button={
          <CustomButton startIcon="add" onClickFn={handleAppendItem}>
            Incluir item
          </CustomButton>
        }
      >
        <Stack sx={{gap: pxToRem(16)}}>
          {fields.length === 0 && (
            <Typography>Não existem itens de carga no processo.</Typography>
          )}
          {fields.map((field, index) => (
            <PackageItem
              field={field}
              index={index}
              key={field.idForm}
              handleDeleteLogisticPackage={handleDeleteLogisticPackage}
              loadIndex={props.loadIndex}
              setHouseMeasurements={setHouseMeasurements}
            />
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default ProcessPackages;
